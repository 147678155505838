<script setup lang="ts">
import type { Specialist } from "~/types/specialist";
import RatingStars from "~/components/custom-ui/RatingStars.vue";

const {t} = useI18n()
const { specialist } = defineProps({
    specialist: {
        type: Object,
        required: true,
    }
}) as { specialist: Specialist };
const formatDate = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric'};
    return date.toLocaleDateString('en-US', options);
};

const formattedDate = computed(() => formatDate(specialist.last_review?.created_at))
</script>

<template>
    <div class="specialist specialist_card h-full p-6 flex flex-col justify-between bg-stroke">

        <!-- TODO: Добавить, когда будет PRO -->
        <!-- <div v-if="false" class="specialist__highlight">PRO
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.72372 0.600006H12.2761C12.3708 0.599993 12.4641 0.622383 12.5485 0.665347C12.6329 0.708311 12.7059 0.770628 12.7615 0.847206L15.0553 4.00081C15.0961 4.05692 15.1162 4.12543 15.1121 4.19468C15.1081 4.26392 15.0802 4.32963 15.0331 4.38061L8.22012 11.7612C8.16614 11.8195 8.09122 11.8541 8.0118 11.8572C7.93238 11.8604 7.85495 11.8319 7.79652 11.778C7.79232 11.7744 5.51652 9.30901 0.966722 4.38061C0.919685 4.32963 0.891766 4.26392 0.887718 4.19468C0.883671 4.12543 0.903744 4.05692 0.944522 4.00081L3.23832 0.847206C3.29399 0.770628 3.36699 0.708311 3.45135 0.665347C3.53572 0.622383 3.62905 0.599993 3.72372 0.600006ZM4.02972 1.80001L2.34972 4.11001L7.99992 10.23L13.6501 4.11001L11.9701 1.80001H4.02972Z"
            fill="#6E330C"/>
      </svg>
    </div> -->
        <div class="specialist_card__top">
            <nuxt-link :to="`/catalog/master-${specialist.id}/portfolio-${specialist.portfolio?.id}`" class="specialist__information">
                <CustomUiUserAvatar :src="specialist.avatar" :name="specialist.name" size="sm" />
                <div class="specialist__main flex-flex-col justify-center">
                    <p class="text-main font-medium mb-2">{{ specialist.name }}</p>
                    <div class="specialist__meta">
                        <div v-if="specialist.rating" class="specialist__rating flex items-center">
                            <span>{{ $t('User rating:') }}</span>
                            <Icon name="ri:star-fill" size="14px" class="text-accent mt-0.5 mx-1" />
                            <span>{{ specialist.rating }}</span> 
                        </div>
                        <div v-if="specialist.completed_tasks_count" class="specialist__counter">{{t('Completed {count} tasks', {count: specialist.completed_tasks_count})}}</div>
                    </div>
                </div>
            </nuxt-link>

            <div class="specialist__tags mt-4">
                <NuxtLink :to="`/catalog/sub-category-${item.id}`" v-for="item in specialist.portfolio?.specifications"
                    class="tags__item">{{ item.name }}
                </NuxtLink>
            </div>

            <div class="specialist__experience mt-4">{{ specialist.portfolio?.description }}</div>
        </div>

        <div class="specialist_card__bottom mt-4">
            <div v-if="specialist.last_review" class="specialist__feedback">
                <div class="grid" v-if="specialist.last_review?.customer">
                    <span class="client_name">{{ specialist.last_review?.customer.name }}</span>
<!--                    <span class="category">Registration, liquidation of companies</span>-->
                </div>
                <div class="description">
                    {{ specialist.last_review?.comment }}
                </div>
                <div class="footer">
                    <RatingStars :rating="specialist.last_review?.rating || 5" />
                    <span class="feedback__date">{{ formattedDate }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
.specialist {
    border-radius: 36px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: relative;

    &__information {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    &__rating {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        letter-spacing: -0.015em;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #525866;
    }

    &__counter {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.015em;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #525866;
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .tags__item {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 8px;
            border: 1px solid #2D9F75;
            border-radius: 999px;
            flex: none;
            order: 0;
            flex-grow: 0;
            background: none;
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #2D9F75;
        }
    }

    &__experience {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        font-style: normal;
        font-size: 12px;
        line-height: 20px;
        color: #525866;
    }

    &__feedback {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
        gap: 8px;
        background: #FFFFFF;
        border: 1px solid #E2E4E9;
        border-radius: 12px;

        .client_name {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            color: #0A0D14;
        }

        .category {
            font-size: 10px;
            font-weight: 400;
            line-height: 12.1px;
            letter-spacing: -0.006em;
            text-align: left;
            color: #525866;
        }

        .description {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: 'Inter', serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            letter-spacing: -0.006em;
            text-align: left;
            color: #0A0D14;
        }

        .footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    &__highlight {
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        width: 53px;
        height: 20px;
        border-radius: 10px;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 2px 2px 2px 8px;
        gap: 2px;
        background: #FFDAC2;
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
        color: #6E330C;
        flex: none;
        order: 0;
        flex-grow: 0;

    }

    .feedback__date{
        font-size: 10px;
    }
}
</style>