<script setup lang="ts">
import {onClickOutside} from '@vueuse/core'
import {Role} from "~/types/role";

const {role} = defineProps({
  role: {
    type: String,
    required: true,
  }
});

const open = ref(false)

const target = ref(null)

function clear() {
  open.value = false
}

onClickOutside(target, clear)

</script>

<template>
  <div class="search__container">
    <div class="search__column min-w-[330px]">
      <img src="/img/bg-search-left.png" class="image"/>
    </div>
    <div class="search__column search__column--search mx-auto">
      <h1 class="search__title" v-if="role === Role.CUSTOMER">Find a <span class="search__title--highlight">reliable person</span> <br>for any task</h1>
      <h1 class="search__title" v-if="role === Role.MASTER">Find a <span class="search__title--highlight">reliable task</span> <br>right now</h1>
      <slot></slot>
    </div>
    <div class="search__column min-w-[330px]">
      <img src="/img/bg-search-right.png" class="image"/>
    </div>

  </div>
</template>

<style scoped lang="scss">
.search {
  margin-top: 80px!important;
  &__container {
    background: #0C3B2E;
    height: 500px;
    width: 100%;
    border-radius: 20px;
    padding: 48px 20px;
    display: flex;
    justify-content: space-between;
    gap: 46px;
  }

  &__column {
    .image {
      max-height: 100%;
      max-width: 330px;
    }

    &--search {
      display: flex;
      flex-direction: column;
      align-content: center;
      gap: 50px;
      margin-top: 70px;
      width: inherit;
    }
  }

  &__title {
    font-family: Rubik, serif;
    font-size: 46px;
    font-weight: 700;
    line-height: 55.2px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #FFFFFF;

    &--highlight {
      font-weight: 800;
      color: #FEB902;
    }
  }
}
</style>