export const heads = {
    index: {
        title: 'Find Jobs and Hire Professionals in UAE - YourService.ae',
        meta: [
            {
                name: 'description',
                content: 'Discover top job opportunities and hire the best professionals in the UAE with YourService.ae. Start your journey to a successful career or find the right talent for your business today.'
            },
            {
                property: 'og:title',
                content: 'Find Jobs and Hire Professionals in UAE - YourService.ae'
            },
            {
                property: 'og:description',
                content: 'Discover top job opportunities and hire the best professionals in the UAE with YourService.ae. Start your journey to a successful career or find the right talent for your business today.'
            }
        ]
    },
}